import { ChevronDown, ChevronUp, Menu, X } from 'lucide-react'
import { useEffect, useState } from 'react'

import dolarAppLogo from '@/assets/images/dolarapp-logo.svg'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@/components/ui'
import { AVAILABLE_COUNTRIES } from '@/constants/countries'
import { AVAILABLE_LANGUAGES } from '@/i18n'
import { getAvailableCountry } from '@/lib/country'
import { cn, createPath } from '@/lib/utils'
import { openDialog } from '@/store'

type Props = {
  language: string
  country?: string
  pathname: string
  translations: {
    blog: string
    faq: string
    personal: string
    business: string
    download: string
    langRegion: string
  }
}

export const BurgerMenu = ({
  country,
  language,
  pathname,
  translations,
}: Props) => {
  const [isNavOpen, setIsNavOpen] = useState(false)

  const userCountry = AVAILABLE_COUNTRIES.find((c) => {
    const browserCountry = getAvailableCountry()

    if (country) {
      return c.code === country
    }

    return c.code === browserCountry
  })

  useEffect(() => {
    if (isNavOpen) {
      document.body.style.overflow = 'hidden'
    }

    return () => {
      document.body.style.overflow = 'unset'
    }
  })

  const isPT_BR = country === 'BR' || language === 'pt'
  const isMX = country === 'MX'

  return (
    <>
      <button
        className="flex items-center justify-center w-8 h-8 rounded-md bg-neutral-gray-900"
        onClick={() => setIsNavOpen((prev) => !prev)}
      >
        <Menu className="text-white w-5 h-5" />
      </button>

      <nav
        className={cn(
          'flex flex-col top-0 fixed bg-neutral-gray-1000 text-white h-screen w-full left-0 z-50 animate-in fade-in slide-in-from-bottom-12 duration-500 overflow-auto',
          {
            hidden: !isNavOpen,
          },
        )}
      >
        <div className="flex px-6 py-4 items-center justify-between shadow-tiny">
          <a href={`/${language}-${userCountry?.code}`}>
            <img src={dolarAppLogo.src} alt="Logo" className="w-32 h-10" />
          </a>
          <button
            className="flex items-center justify-center rounded-md bg-neutral-gray-900 w-8 h-8"
            onClick={() => setIsNavOpen((prev) => !prev)}
          >
            <X className="text-white w-5 h-5" />
          </button>
        </div>
        <ul className="flex flex-col gap-8 px-12 py-8">
          {!isMX ? null : (
            <li>
              <a
                href={`/${language}-${userCountry?.code}`}
                className="text-xl font-semibold text-white hover:text-emerald-300 transition-colors"
              >
                {translations.personal}
              </a>
            </li>
          )}

          {!isMX ? null : (
            <li>
              <a
                href={`/${language}-${userCountry?.code}/business`}
                className="text-xl font-semibold text-white hover:text-emerald-300 transition-colors"
              >
                {translations.business}
              </a>
            </li>
          )}

          {isPT_BR ? null : (
            <li>
              <a
                href={`/${language}-${userCountry?.code}/blog`}
                className="text-xl font-semibold text-white hover:text-emerald-300 transition-colors"
              >
                {translations.blog}
              </a>
            </li>
          )}
          <li>
            <a
              href={`https://intercom.help/dolarapp/${language ?? 'en'}/`}
              className="text-xl font-semibold text-white hover:text-emerald-300 transition-colors"
            >
              {translations.faq}
            </a>
          </li>
          <li>
            <p className="text-xl font-semibold text-white hover:text-emerald-300 transition-colors">
              {translations.langRegion}
            </p>

            <div className="pl-4">
              <CountrySelector
                country={userCountry?.code}
                language={language}
                pathname={pathname}
              />
              <LanguagePicker
                country={userCountry?.code}
                language={language}
                pathname={pathname}
              />
            </div>
          </li>
        </ul>

        <button
          onClick={openDialog}
          className="bg-primary hover:bg-primary-dark font-bold text-xl text-white px-8 py-3 rounded-md transition-colors w-fit mx-auto mt-auto mb-24"
        >
          {translations.download}
        </button>
      </nav>
    </>
  )
}

type SelectorType = Omit<Props, 'translations'>

const CountrySelector = ({ country, language, pathname }: SelectorType) => {
  const [open, setOpen] = useState(false)

  const search = import.meta.env.SSR ? '' : window.location.search

  const getPath = createPath(pathname, search)

  const isBusiness = import.meta.env.SSR
    ? false
    : window.location.pathname.includes('business')

  const userCountry = AVAILABLE_COUNTRIES.find((c) => {
    return c.code === country
  })

  const nonSelectedCountries = AVAILABLE_COUNTRIES.filter(
    (c) => c.code !== country,
  )

  return (
    <Collapsible open={open} onOpenChange={setOpen}>
      <CollapsibleTrigger
        onClick={(e) => {
          if (isBusiness) {
            e.preventDefault()
          }
        }}
      >
        <div className="flex items-center gap-3 pt-4">
          <img
            className="border border-white rounded-lg w-6 h-6 object-cover"
            src={`https://flagcdn.com/h120/${userCountry?.code.toLocaleLowerCase()}.webp`}
            alt={userCountry?.name}
          />
          <p className="font-2xl text-white font-semibold">
            {userCountry?.name}
          </p>
          <div>
            {open ? (
              <ChevronUp className="text-white w-8 h-8" />
            ) : (
              <ChevronDown className="text-white w-8 h-8" />
            )}
          </div>
        </div>
      </CollapsibleTrigger>
      <CollapsibleContent className="data-[state=open]:animate-collapsible-down data-[state=closed]:animate-collapsible-up">
        <div className="pl-4 pt-4 space-y-4">
          {nonSelectedCountries.map((c) => (
            <a
              href={getPath({
                country: c.code,
                lang: language,
              })}
              key={c.name}
              className="flex items-center gap-3"
            >
              <img
                className="border border-white rounded-lg w-5 h-5 object-cover"
                src={`https://flagcdn.com/h120/${c.code.toLocaleLowerCase()}.webp`}
                alt={c.name}
              />
              <p className="font-2xl text-white">{c.name}</p>
            </a>
          ))}
        </div>
      </CollapsibleContent>
    </Collapsible>
  )
}

const LanguagePicker = ({ country, language, pathname }: SelectorType) => {
  const [open, setOpen] = useState(false)
  const search = import.meta.env.SSR ? '' : window.location.search

  const userCountry = AVAILABLE_COUNTRIES.find((c) => {
    return c.code === country
  })

  const getPath = createPath(pathname, search)

  const userLanguage = AVAILABLE_LANGUAGES.filter((l) => l === language)

  const filteredLanguages = AVAILABLE_LANGUAGES.filter(
    (lang) => !lang.includes('-') && lang !== language,
  )

  return (
    <Collapsible open={open} onOpenChange={setOpen}>
      <CollapsibleTrigger>
        <div className="flex items-center gap-3 pt-2 ">
          <p className="font-2xl text-white font-semibold uppercase">
            {userLanguage}
          </p>
          {open ? (
            <ChevronUp className="text-white w-8 h-8" />
          ) : (
            <ChevronDown className="text-white w-8 h-8" />
          )}
        </div>
      </CollapsibleTrigger>
      <CollapsibleContent className="data-[state=open]:animate-collapsible-down data-[state=closed]:animate-collapsible-up">
        <div className="pl-4 pt-4 space-y-4">
          {filteredLanguages.map((lang) => (
            <a
              key={lang}
              href={getPath({
                country: userCountry?.code,
                lang,
              })}
              className="font-2xl text-white font-semibold uppercase flex"
            >
              {lang}
            </a>
          ))}
        </div>
      </CollapsibleContent>
    </Collapsible>
  )
}
